@import '~antd/dist/reset.css';
body {
  background-color: #f1f1f1;
}

a {
  color :#00429E;
}

.container {
  display: flex;
  flex-direction: column; 
  min-height: 100vh;
}

.top {
  width: 100%;
  padding: 5px;
  background-color: #2E5569;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.main{
  flex-grow: 1; 
}

.img_wh {
  max-width: 50vw;
  max-height: 50vh;
}

.bottom {
  height: 80px;
  text-align: center;
  color: #FFFFFF;
  background-color: #2E5569;
}

.common_text {
  color: #2E5569;
  font-size: 1.5em;
  font-weight: bold;
}

.top_user {
  margin: 4px 10px 4px 0px;
  color: #FFFFFF;
  font-weight: bold;
}

.top_name{
  margin: 10px;
  color: #FFFFFF;
  font-size: 1.5em;
  font-weight: bold;
}

.align_rtc {
  text-align: right;
}

.search {
  top: 50px;
  padding: 20px;
  background-color: #FFFFFF;
}

.detail {
  margin: 20px;
  padding: 20px;
  background-color: #FFFFFF;
}

.detail_item {
  margin-top: 15px;
}

/* .card_title {
  display: flex;
  align-items: center;
} */

.card_title > .card_content {
  width: 20%;
  color: grey;
  font-weight: normal;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.like-star {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.button-container {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0;
}

.record-date {
  color: #737373;
  display: inline;
}

/* antd css  */
.ant-card-actions > li {
  margin: 0;
}

.go-to-top {
    position: fixed;
    top: 90%;
    right: 5%;
    z-index: 2;
}

.pa_20{
  padding: 20px;
}

.title_pb20{
  font-size: 1.25em;
  font-weight: bold;
  color: #7a7a7a;
  padding: 0px 20px;
}

.content_box {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.memo {
  color: #2E5569;
  margin-left: 4px;
  font-size: 0.625em;
}

/* btn primary style  */
.ant-btn-primary {
  color: #fff;
  border-color: #547A92;
  background: #547A92;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.ant-btn-primary:hover {
  color: #547A92;
  border-color: #547A92;
  background: #fff;
}

.ant-btn-primary:focus {
  color: #fff;
  border-color: #547A92;
  background: #547A92;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-ghost {
  border-color: #f1f1f1;
  color: #FFF;
  background-color: #2E5569;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.ant-btn-ghost:hover {
  border: #f1f1f1;
  color: #FFF;
  background: rgba(255,255,255,0.4);
}

.ant-btn-ghost:focus {
  border-color: #f1f1f1;
  color: #FFF;
  background-color: #2E5569;
}

.ant-input-search-button {
  width: 32px;
  height: 32px;
}

.ant-btn-icon-only{
  width: 32px;
  height: 32px;
}

/* Comment  */
.ant-comment-nested {
  background-color: white;
  margin: 0;
}

.ant-collapse-borderless {
  background-color: white;
}

.ant-card .ant-card-actions>li {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}

/* WAVE Evaluation Tool changes*/
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected {
  color: #2E5569;
}

.ant-tag-green {
  color: #2F850A;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-orange {
  color: #B15906;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #00429E;
}

.ant-pagination .ant-pagination-item-active a {
  color: #00429E;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #6E6E6E ;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #00429E;
}

.ant-select .ant-select-selection-placeholder {
  color: #737373;
}

.ant-input::placeholder {
  color: #737373 ;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: #737373;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2E5569;
}

.ant-btn-default.ant-btn-dangerous {
  color: #dc3a04;
  border-color: #dc3a04;
}

.ant-comment .ant-comment-content-author-time {
  color: #737373;
}

@media (max-width: 576px) {

  .like-star {
    justify-content: start;
    flex-direction: row;
  }
  
  .button-container {
    flex-direction: column;
  }

  .button-container > button {
    width: 100%;
  }

  .record-date {
    color: #737373;
    display: block;
  }

  .align_rtc {
    text-align: center;
  }
}
